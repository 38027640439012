import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"
import ProductCategory from "../../components/ProductCategory/ProductCategory"
import PageLinks from "../../components/PageLinks/PageLinks"

const highspeed = () => {
  return (
    <>
      <Seo title="고속가공기" />
      <Layout>
        <SimpleBanner subtitle="방전가공" title="고정밀 고속가공기">
          <StaticImage
            className="banner__image"
            src="../../images/chmer/chmerpic2-min.png"
            layout="constrained"
            placeholder="blurred"
          />
        </SimpleBanner>
        <ProductCategory productCategory="highspeed" />
        <PageLinks />
      </Layout>
    </>
  )
}

export default highspeed
